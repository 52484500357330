import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { blankCheck, nameCheck, dceCheck, sectionCheck } from '../../Errors';
import { USERID } from '../../App';
import { useNavigate } from 'react-router-dom';

export default function CSV(props) {
  const [orderedInput, setOrderedInput] = useState([])
  const [parsing, setParsing] = useState(false)
  const [finished, setFinished] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  function sendData(newUser) {
    const url = `${props.constants.hostname}/api/secretary/register?userInfo=${JSON.stringify(newUser)}`
    return new Promise((resolve, reject) => {
      fetch(url,
        {
          body: JSON.stringify({ user: newUser }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem(USERID)}`
          },
          method: 'POST',
        })
      .then((res) => {
        if (!res.ok) {
          reject(new Error('Entry already exists'))
        } else {
          resolve();
        }
      });
    })
  }

  function errorCheck(newUser) {
    if (!blankCheck(newUser.FirstName)) {
      if (nameCheck(newUser.FirstName)) {
        newUser.FirstNameError = 'Invalid Entry'
      } else {
        newUser.FirstNameError = ''
      }
    } else {
      newUser.FirstNameError = 'Blank Entry'
    }
    if (!blankCheck(newUser.LastName)) {
      if (nameCheck(newUser.LastName)) {
        newUser.LastNameError = 'Invalid Entry'
      } else {
        newUser.LastNameError = ''
      }
    } else {
      newUser.LastNameError = 'Blank Entry'
    }
    if (!blankCheck(newUser.DCE)) {
      if (dceCheck(newUser.DCE)) {
        newUser.DCEError = 'Invalid Entry'
      } else {
        newUser.DCEError = ''
      }
    } else {
      newUser.DCEError = 'Blank Entry'
    }
    if (!blankCheck(newUser.Section)) {
      if (sectionCheck(newUser.Section)) {
        newUser.SectionError = 'Invalid Entry'
      } else {
        newUser.SectionError = ''
      }
    } else {
      newUser.SectionError = 'Blank Entry'
    }
  }

  function resolveData(errorInput) {
    if (errorInput.length !== 0) {
      setOrderedInput(errorInput)
      setError(true)
    } else {
      setSubmit(true)
    }
  }

  async function submitUpload() {
    const errorInput = [];
    let counter = 0;
    const elementsToAdd = orderedInput.length
    for (let i = 0; i < orderedInput.length; i++) {
      const newUser = orderedInput[i];
      errorCheck(newUser);
      if (newUser.FirstNameError !== '' || newUser.LastNameError !== '' ||
        newUser.DCEError !== '' || newUser.SectionError !== '') {
        errorInput[counter] = newUser;
        counter++;
        if (i + 1 === elementsToAdd) {
          resolveData(errorInput);
        }
      } else {
        await sendData(newUser, i)
        .then(() => {
          if (i + 1 === elementsToAdd) {
            resolveData(errorInput);
          }
        })
        // eslint-disable-next-line
        .catch(() => {
          errorInput[counter] = newUser;
          counter++;
          if (i + 1 === elementsToAdd) {
            resolveData(errorInput);
          }
        })
      }
    }
  }

  function confirm(user) {
    if (window.confirm('Delete this user?')) {
      const tempArray = orderedInput;
      for (let i = 0; i < orderedInput.length; i++) {
        const tempUser = orderedInput[i];
        if (tempUser.DCE === user.DCE && tempUser.FirstName === user.FirstName && tempUser.LastName === user.LastName) {
          tempArray.splice(i, 1)
          setOrderedInput(tempArray)
          break;
        }
      }
    }
  }

  function elementChange(arrayIndex, elementType, event) {
    const fixedInput = [...orderedInput];
    if (elementType === 'FirstName') {
      fixedInput[arrayIndex].FirstName = event.target.value
    }
    else if (elementType === 'LastName') {
      fixedInput[arrayIndex].LastName = event.target.value
    }
    else if (elementType === 'Section') {
      fixedInput[arrayIndex].Section = event.target.value
    }
    else if (elementType === 'DCE') {
      fixedInput[arrayIndex].DCE = event.target.value
      fixedInput[arrayIndex].Email = `${event.target.value}@rit.edu`
    }
    setOrderedInput(fixedInput)
  }

  function readFile(event) {
    setParsing(true)
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      const rows = text.split('\n');
      let first = true;
      const order = [];
      let counter = 0;
      const reorderedElements = [];
      rows.forEach((element) => {
        const rowItems = element.split(',');
        if (first) {
          //  Going to create an array to map rows to their proper values
          //  This fixes the data so no matter what order the columns are in,
          //  the order the system expects it to be in is kept
          for (let i = 0; i < rowItems.length; i++) {
            rowItems[i] = rowItems[i].replace(/["']+/g, '').replace(/\s/g, '').trim()
            if (rowItems[i].toLowerCase() === 'firstname') {
              order[0] = i;
            } else if (rowItems[i].toLowerCase() === 'lastname') {
              order[1] = i;
            } else if (rowItems[i].toLowerCase() === 'dce') {
              order[2] = i;
            }
            else if (rowItems[i].toLowerCase() === 'section') {
              order[3] = i;
            }
          }
          first = false;
          if (order.length === 0) {
            alert('Please make sure the file is a CSV and has column headers');
            return
          }
        }
        else if (rowItems[0] !== '') {
          rowItems[order[0]] = rowItems[order[0]].replace(/["']+/g, '').replace(/\s/g, '').trim()
          rowItems[order[1]] = rowItems[order[1]].replace(/["']+/g, '').replace(/\s/g, '').trim()
          rowItems[order[2]] = rowItems[order[2]].replace(/["']+/g, '').replace(/\s/g, '').trim()
          // eslint-disable-next-line
          rowItems[order[3]] = rowItems[order[3]].replace(/["']+/g, '').replace(/\s/g, '').trim().replace(/\w\S*/g, function (item) { return item.charAt(0).toUpperCase() + item.substr(1).toLowerCase() })
          const newUser = {
            FirstName: rowItems[order[0]],
            LastName: rowItems[order[1]],
            Email: `${rowItems[order[2]]}@rit.edu`,
            DCE: rowItems[order[2]],
            Role: 'M',
            Section: rowItems[order[3]],
            Status: 'F',
            JoinYear: new Date().getMonth() + 1 <= 7 ? new Date().getFullYear() - 1 : new Date().getFullYear(),
            Retained: 0,
            Exempt: 0,
            ExemptDesc: '',
            Edit: false,
            FirstNameError: '',
            LastNameError: '',
            DCEError: '',
            SectionError: ''
          }
          errorCheck(newUser)
          reorderedElements[counter] = newUser;
          counter++;
        }
      });
      setOrderedInput(reorderedElements)
      setParsing(false)
      setFinished(true)
    }
    reader.readAsText(file);
  }

  if(submit) {
    navigate('/secretary/users')
  }

  return (
    <div>
      {error ?
        <h2>The following elements could not be added. Please check to ensure they don't already exist</h2>
        :
        null
      }
      <h3>File Upload</h3>
      <hr />
      <div>
        <div className='oneline'>
          <p className='fileInputTag'>Select a CSV file to upload:
            <input type='file' className='fileInput' onChange={(e) => readFile(e)} />
          </p>
          {parsing ?
            <div className='loader'></div>
            :
            null
          }
        </div>
        {finished ?
          <div>
            <div className='oneline'>
              <p className='fileInfo'>Elements have finished loading!</p>
              <p className='fileInfo' style={{marginLeft: '3px'}}>Please review the following entries and make any changes.</p>
            </div>
            <div className='oneline'>
              <p className='fileInfo'>When done, please hit the submit button.</p>
              <button className='fileSubmit' onClick={submitUpload}>Submit</button>
            </div>
          </div>
          :
          null
        }
        </div>
        <div className='tableDiv'>
          <table className='userTable'>
            <thead>
              <tr className='userRow userHeaderRow'>
                <th className='userHeader'>First Name</th>
                <th className='userHeader'>Last Name</th>
                <th className='userHeader'>DCE</th>
                <th className='userHeader'>Section</th>
                <th className='userHeader'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {orderedInput.map((user, index) =>
                <tr key={index} className='userRow'>
                  <td className='userEntry'>
                    {orderedInput[index].FirstNameError === '' ?
                    <input
                      type='text'
                      onChange={(e) => elementChange(index, 'FirstName', e)}
                      value={orderedInput[index].FirstName}
                    />
                    :
                    <div className='tooltip'>
                      <input
                        type='text'
                        style={{background: 'yellow'}}
                        onChange={(e) => elementChange(index, 'FirstName', e)}
                        value={orderedInput[index].FirstName}
                      />
                      <span className='tooltiptext'>
                        {orderedInput[index].FirstNameError}
                      </span>
                    </div>
                    }
                  </td>
                  <td className='userEntry'>
                    {orderedInput[index].LastNameError === '' ?
                    <input
                      type='text'
                      onChange={(e) => elementChange(index, 'LastName', e)}
                      value={orderedInput[index].LastName}
                    />
                    :
                    <div className='tooltip'>
                      <input
                        type='text'
                        style={{background: 'yellow'}}
                        onChange={(e) => elementChange(index, 'LastName', e)}
                        value={orderedInput[index].LastName}
                      />
                      <span className='tooltiptext'>
                        {orderedInput[index].LastNameError}
                      </span>
                    </div>
                    }
                  </td>
                  <td className='userEntry'>
                    {orderedInput[index].DCEError === '' ?
                    <input
                      type='text'
                      onChange={(e) => elementChange(index, 'DCE', e)}
                      value={orderedInput[index].DCE}
                    />
                    :
                    <div className='tooltip'>
                      <input
                        type='text'
                        style={{background: 'yellow'}}
                        onChange={(e) => elementChange(index, 'DCE', e)}
                        value={orderedInput[index].DCE}
                      />
                      <span className='tooltiptext'>
                        {orderedInput[index].DCEError}
                      </span>
                    </div>
                    }
                  </td>
                  <td className='userEntry'>
                    {orderedInput[index].SectionError === '' ?
                    <select
                      value={orderedInput[index].Section}
                      onChange={(e) => elementChange(index, 'Section', e)}
                      className='secretaryInput'
                    >
                      <option default value='---'>---</option>
                      <option value='Flutes'>Flutes</option>
                      <option value='Clarinets'>Clarinets</option>
                      <option value='Saxophones'>Saxophones</option>
                      <option value='Trumpets'>Trumpets</option>
                      <option value='Mellophones'>Mellophones</option>
                      <option value='Tenor Saxophones'>Tenor Saxophones</option>
                      <option value='Trombones'>Trombones</option>
                      <option value='Lows'>Lows</option>
                      <option value='Percussion'>Percussion</option>
                    </select>
                    :
                    <div className='tooltip'>
                      <select
                        value={orderedInput[index].Section}
                        style={{background: 'yellow'}}
                        onChange={(e) => elementChange(index, 'Section', e)}
                        className='secretaryInput'
                      >
                        <option default value='---'>---</option>
                        <option value='Flutes'>Flutes</option>
                        <option value='Clarinets'>Clarinets</option>
                        <option value='Saxophones'>Saxophones</option>
                        <option value='Trumpets'>Trumpets</option>
                        <option value='Mellophones'>Mellophones</option>
                        <option value='Tenor Saxophones'>Tenor Saxophones</option>
                        <option value='Trombones'>Trombones</option>
                        <option value='Lows'>Lows</option>
                        <option value='Percussion'>Percussion</option>
                      </select>
                      <span className='tooltiptext'>
                        {orderedInput[index].SectionError}
                      </span>
                    </div>
                    }
                  </td>
                  <td className='userEntry'>
                    <FontAwesomeIcon
                      icon='trash-alt'
                      className='faDelete'
                      onClick={() => confirm(index, user)}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </div>
  );
}